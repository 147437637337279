@import url(https://fonts.googleapis.com/css2?family=Rubik&display=swap);
.App {
  text-align: center;
  font-family: 'Rubik', sans-serif;
}

.profile-picture {
  border-radius: 50%;

  box-shadow: 0px 0px 10px 0px black;

  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #104455;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.icon-links-container {
  display: flex;
  justify-content: space-around;

  width: 150px;
}

.icon-link {
  color: white;
  transition: all 0.5s;
}

.icon-link:hover {
  transform: scale(2);
}

.github:hover {
  color: #222;
}

.twitter:hover {
  color: #1da1f2;
}

.linkedin:hover {
  color: #0a66c2;
}

